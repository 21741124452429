import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import "../../styles/NavBar.css";
import { NavLink } from "react-router-dom";

function NavBar() {
  const logo = require("../../media/images/logo.webp");
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="border-solid border-2 border-light-black border-b-gray-700 pb-[0.8em] z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center mt-4">
            <div className="flex-shrink-1">
              <NavLink to="/">
                <img className="h-11 w-11" src={logo} alt="Logo" />
              </NavLink>
            </div>
            <div className="hidden md:block">
              <div className="lg:ml-[20em] md:ml-[6em] ml-[6em] flex items-baseline space-x-4">
                <div>
                  <NavLink
                    to="/morphs"
                    className={
                      "hover:bg-zinc-700 text-white px-3 py-2 rounded-md text-xl font-large"
                    }
                  >
                    Morphs
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/calculator"
                    className={
                      "hover:bg-zinc-700 text-white px-3 py-2 rounded-md text-xl font-large ml-[5em]"
                    }
                  >
                    Calculator
                  </NavLink>
                </div>
                <div>
                  <NavLink
                    to="/contact"
                    className={
                      "hover:bg-zinc-700 text-white px-3 py-2 rounded-md text-xl font-large ml-[5em]"
                    }
                  >
                    Contact
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="bg-back inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded="false"
            >
              <span className="sr-only bg-back">Open main menu</span>
              {!isOpen ? (
                <svg
                  className="block h-6 w-6 "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <NavLink
                to="/morphs"
                className={
                  "text-gray-300 hover:bg-zinc-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                }
              >
                Morphs
              </NavLink>

              <NavLink
                to="/calculator"
                className={
                  "text-gray-300 hover:bg-zinc-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                }
              >
                Calculator
              </NavLink>

              <NavLink
                to="/contact"
                className={
                  "text-gray-300 hover:bg-zinc-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                }
              >
                Contact
              </NavLink>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  );
}

export default NavBar;
