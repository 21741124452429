function CombinationSVG(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520.963 520.964"
	  className={props.className}
	  fill="#fff"
    >
      <g>
        <path
          d="M519.296,355.159c1.052,0.798,1.667,2.057,1.667,3.381s-0.615,2.583-1.667,3.381l-104.78,80.564
		c-1.531,1.17-3.647,1.182-5.178,0.023c-1.537-1.165-2.104-3.198-1.401-4.989l17.461-44.188
		c-79.358-2.985-124.345-31.321-155.813-64.203c11.709-13.358,21.426-26.899,30.292-39.253c4.397-6.142,8.618-11.999,12.851-17.603
		c23.867,28.064,52.919,48.119,112.422,50.845l-17.212-43.562c-0.697-1.791-0.13-3.824,1.401-4.989
		c0.762-0.566,1.667-0.862,2.571-0.862c0.916,0,1.844,0.296,2.612,0.881L519.296,355.159z M106.447,246.372
		c0.771,0.594,1.69,0.883,2.61,0.883c0.901,0,1.817-0.295,2.574-0.866c1.537-1.156,2.11-3.195,1.398-4.986l-17.076-43.246
		c59.237,3.777,88.369,24.223,112.295,52.429c4.176-5.526,8.34-11.295,12.672-17.342c8.911-12.424,18.693-26.051,30.482-39.51
		c-31.522-33.023-76.542-61.691-155.935-65.843l17.561-44.449c0.706-1.791,0.133-3.824-1.398-4.989
		c-1.534-1.156-3.649-1.144-5.175,0.027L1.664,159.04C0.612,159.838,0,161.097,0,162.421c0,1.327,0.618,2.586,1.664,3.384
		L106.447,246.372z M288.895,282.008c31.297-43.637,56.56-78.773,136.112-83.845l-17.07,43.246
		c-0.703,1.791-0.136,3.824,1.396,4.986c0.768,0.57,1.666,0.866,2.577,0.866c0.91,0,1.832-0.295,2.606-0.884l104.78-80.567
		c1.052-0.798,1.667-2.057,1.667-3.384c0-1.324-0.615-2.583-1.667-3.381l-104.78-80.566c-1.531-1.171-3.647-1.183-5.178-0.027
		c-1.537,1.165-2.104,3.198-1.401,4.989l17.567,44.449c-116.738,6.115-159.195,65.213-193.628,113.221
		c-31.2,43.515-56.241,78.353-136.057,82.006l17.209-43.562c0.706-1.791,0.133-3.824-1.398-4.989
		c-0.756-0.566-1.667-0.862-2.574-0.862c-0.914,0-1.838,0.296-2.61,0.881L1.664,355.159C0.612,355.957,0,357.216,0,358.54
		s0.618,2.583,1.664,3.381l104.783,80.564c1.534,1.17,3.65,1.182,5.175,0.023c1.54-1.165,2.113-3.198,1.401-4.989l-17.458-44.188
		C212.158,388.951,254.585,329.861,288.895,282.008z"
        />
      </g>
    </svg>
  );
}

export default CombinationSVG;
