import React from "react";
import { MetaTags } from "react-meta-tags";
import { Carousel } from "3d-react-carousal";
import { Link } from "react-router-dom";

import NavBar from "../Common/NavBar";

import "../../styles/HomePage.css";

function HomePage() {
  let slides = [
    <img src={'/images/morphs/recessive/leucistic.webp'} alt="Leucistic" />,
    <img src={'/images/morphs/combo/superarcticanaconda.webp'} alt="Superarctic Anaconda" />,
    <img src={'/images/morphs/combo/lavenderanaconda.webp'} alt="Lavender Anaconda" />,
  ];
  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Home</title>
        <meta
          name="description"
          content="Hognose Morphs is the most complete online morph database and guide for the western
  hognose snakes. Complete with recessive, dominant, incomplete dominant, polygenic, combinations and other morphs."
        />
      </MetaTags>
      <NavBar />
      <div className="home-content grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-10">
        <div className="">
          <h1 className="lg:ml-[2em] lg:mt-[2em] lg:text-7xl md:text-5xl ml-[1em] mt-[2em] text-[2em]">
            ALL HOGNOSE MORPHS
          </h1>
          <h2 className="lg:ml-[3em] lg:mt-3 lg:text-5xl md:text-4xl md:ml-[1.25em] ml-[1em] mt-[0.2em] text-3xl mx-auto">
            ONE PLACE
          </h2>
          <Link to="/morphs">
            <button className="content-button lg:ml-[6em] lg:mt-[2em] md:ml-[1.9em] md:text-[1.5em] mt-[2em] ml-[1.4em] text-[1.2em]">Morphs</button>
          </Link>
        </div>
        <div>
          <div className="lg:mt-[25vh] md:min-h-[26em] min-h-[22em]">
            <Carousel slides={slides} autoplay={false} interval={1000} />
          </div>
        </div>
      </div>
      <h3 className="lg:ml-[6em] lg:mt-[-2em] lg:text-2xl md:mt-[0em] md:ml-[2.5em] ml-[1.6em] mt-[-5em] text-lg pb-[1em]">
        IN DEVELOPMENT
      </h3>
    </>
  );
}

export default HomePage;
