import React from "react";
import MorphButton from "../Common/MorphButton";
import "../../styles/MorphsPageBody.css";
import { Link } from "react-router-dom";
import BackArrow from "../../media/svg/BackArrowSVG";
import EyeSVG from "../../media/svg/EyeSVG";
import CombinationSVG from "../../media/svg/CombinationSVG";
import QuestionMarkSVG from "../../media/svg/QuestionMarkSVG";
import DropSVG from "../../media/svg/DropSVG";
import BrokenSVG from "../../media/svg/BrokenSVG";
function MorphsPageBody() {
  return (
    <>
      <h1 className="lg:text-6xl text-5xl text-center mt-[0.3em]">Morphs</h1>

      <div className="ml-[3em] xl:mt-[2em] lg:mt-[0.3em] sm:mt[1em] lg:mb-[1em] morph-selection">
        <div
          id="card-grid"
          className="grid lg:grid-cols-4 md:grid-cols-2 content-center justify-center"
        >
          {/* All Button */}
          <div className="box">
            <Link to="/morphs/all">
              <MorphButton
                mutation="All"
                description="All proven morphs"
                img={
                  <CombinationSVG className="lg:h-[8em] lg:w-[8em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
          {/* Recessive Button */}
          <div className="box">
            <Link to="/morphs/recessive">
              <MorphButton
                mutation="Recessive"
                description="Morphs like Albino and Sable"
                img={
                  <DropSVG className="lg:h-[8em] lg:w-[7em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
          {/* Incomplete Dominant Button */}
          <div className="box">
            <Link to="/morphs/incomplete-dominant">
              <MorphButton
                mutation="Incomplete Dominant"
                description="Morphs like Anaconda and Arctic"
                img={
                  <BrokenSVG className="lg:h-[8em] lg:w-[8em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
          {/* Dominant Button */}
          <div className="box">
            <Link to="/morphs/dominant">
              <MorphButton
                mutation="Dominant"
                description="Morphs like Antarctic and Skullface"
                img={
                  <EyeSVG className="lg:h-[8em] lg:w-[8em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
          
          {/* Polygenic Button */}
          <div className="box lg:col-end-3 lg:col-span-1">
            <Link to="/morphs/polygenic">
              <MorphButton
                mutation="Polygenic"
                description="Morphs like Lemon Ghost"
                img={
                  <BackArrow className="lg:h-[8em] lg:w-[8em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
          {/* Other Button */}
          <div className="box lg:col-end-4 lg:col-span-1">
            <Link to="/morphs/other">
              <MorphButton
                mutation="Other"
                description="Other and unproven morphs"
                img={
                  <QuestionMarkSVG className="lg:h-[8em] lg:w-[8em] svg-size button-img" />
                }
              />{" "}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default MorphsPageBody;
