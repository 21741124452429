import React from "react";

function FilterButton(props) {
  return (
    <>
      <button
        id={props.id}
        className="btn-nonactive btn"
        value={props.value}
        onClick={(e) => props.onClick(e)}
      >
        {props.name}
      </button>
    </>
  );
}

export default FilterButton;
