import React from "react";

import "../../styles/MorphButton.css";



function MorphButton(props) {
  return (
    <>
    <div className="lg:h-[8em] lg:w-[8em] md:h-[8em] md:w-[8em] sm:h-[2em] sm:w-[2em] mt-[-0.5em] center-button">
      {props.img}
    </div>

      <h2 className="text-3xl mb-3 ">{props.mutation}</h2>
      <h3 className="text-xl">{props.description}</h3>
    </>
  );
}

export default MorphButton;
