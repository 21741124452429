import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { MetaTags } from "react-meta-tags";
import axios from "axios";

import NavBar from "../../Common/NavBar";

import "../../../styles/MorphsPages.css";

import MorphList from "../../Common/MorphList";
import FilterButton from "../../Common/FilterButton";
import ScrollButton from "../../Common/ScrollButton";

function AllMorphsPage() {
  const [cardInfo, setCardInfo] = useState([]);
  const [filteredButtons, setFilteredButtons] = useState([]);
  const [array, setArray] = useState([]);
  const [isMorphExisting, setIsMorphExisting] = useState(true);
  const [filtering, setFiltering] = useState([]);
  const [isFilterOpen, setFilterOpen] = useState(false);
  const itemsPerPage = 30; // Define how many items you want per page
  const [loader, setLoader] = useState(null);
  const arr = useRef([]);

  /**
   * errorNotFoundDisplay
   * @description - This function is used to display the no morphs message if there are no morphs found
   * @param {array} err - The array of all the morphs
   * */
  const errorNotFoundDisplay = (err) => {
    if (err.response.status === 404) {
      setArray([]);
    } else {
      console.log(err);
    }
  }

  /**
   * useEffect
   * @description - This useEffect is used to get all the morphs from the database
   * @param {array} filteredButtons - The array of all the morphs
   * */
  useEffect(() => {
    if (filteredButtons.length === 0) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/morphs`)
        .then((res) => {
          setCardInfo(res.data);
          setFiltering([]);
        })
        .catch((err) => {
          errorNotFoundDisplay(err);
        });
    } else if (filteredButtons.length === 1) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/morphs/filter/${filteredButtons[0]}`)
        .then((res) => {
          setCardInfo(res.data);
          setFiltering(filteredButtons);
        })
        .catch((err) => {
          errorNotFoundDisplay(err);
        });
    }
    else if (filteredButtons.length > 0 && filteredButtons.length > 1) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/morphs/filter/${filteredButtons.join(",")}`)
        .then((res) => {
          setCardInfo(res.data);
          setFiltering(filteredButtons);
        })
        .catch((err) => {
          errorNotFoundDisplay(err);
        });
    }
  }, [filteredButtons]);

  /**
   * filterMorphData useCallback to avoid infinite loop
   * @description - This function is used to filter the morphs based on the filtered buttons
   * @param {array} item - The array of all the morphs
   * @return {array} - The array of all the found morphs based on the filters
   */
  const filterMorphData = useCallback(
    (item) => {
      let morphArr = [];
      if (
        filteredButtons.length >= 1
      ) {
        morphArr.push(item);
        return morphArr;
      } else {
        return null;
      }
    },
    [filteredButtons]
  );

  /**
   * useEffect
   * @description - This useEffect is used to display the no morphs message if there are no morphs found
   * @param {array} cardInfo - The array of all the morphs
   */
  useEffect(() => {
    if (array.length === 0) {
      setIsMorphExisting(false);
      arr.current = (
        <div className="lg:text-3xl text-xl text-center mt-[3em] mb-[5em]">
          <p>
            No such morphs found in the database. These are either not yet bred
            or still need to be added.
          </p>
        </div>
      );
      setArray(arr.current);
    }
  }, [array]);

  /**
   * useEffect
   * @description - This useEffect is used to load more morphs when the user scrolls down by using an observer
   * @param {array} loader - The array of all the morphs
   */
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0
    };

    // Create a new IntersectionObserver
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader) {
      observer.observe(loader);
    }

    // Clean up the observer on unmount
    return () => observer.disconnect();
  }, [loader, filtering]);

  /**
   * renderMorphList
   * @description - This function is used to render the morphs
   * @param {array} item - The array of all the morphs
   **/
  const renderMorphList = (item) => {
    return (
      <div className="card" key={item.title}>
        <MorphList items={item} />
      </div>
    );
  };

  /**
   * handleObserver 
   * @description - This function is used to load more data when the user scrolls down
   * @param {*} entities - The entities of the morphs
   * @param {*} observer - The observer of the morphs
   */
  function handleObserver(entities, observer) {
    const target = entities[0];
    if (target.isIntersecting) {
      if (filteredButtons.length === 0) {
        // Load more data here
        setArray(prevArray => {
          // Get the next set of items
          const moreItems = cardInfo
            .slice(prevArray.length, prevArray.length + itemsPerPage)
            .map((item) => (
              renderMorphList(item)
            ));

          // Return the existing items plus the new items
          return [...prevArray, ...moreItems];
        });
      } else if (filteredButtons.length > 0) {
        // Load more data here
        setArray(prevArray => {
          // Get the next set of items
          const moreItems = cardInfo
            .filter((item) => filterMorphData(item))
            .slice(prevArray.length, prevArray.length + itemsPerPage)
            .map((item) => (
              renderMorphList(item)
            ));

          // Return the existing items plus the new items
          return [...prevArray, ...moreItems];
        });
      }
    }
  }

  /**
   * useEffect
   * @description - This useEffect is used to filter the morphs based on the filtered buttons
   * @param {array} filteredButtons - The array of all the morphs
   */
  useEffect(() => {
    if (filteredButtons.length === 0) {
      setIsMorphExisting(true);
      document.getElementById("allButton").className = "btn btn-active";
      arr.current = cardInfo
        .slice(0, itemsPerPage)
        .map((item) => (
          renderMorphList(item)
        ));
    } else if (filteredButtons.length > 0) {
      setIsMorphExisting(true);
      arr.current = cardInfo
        .slice(0, itemsPerPage)
        .filter((item) => filterMorphData(item))
        .map((item) => (
          renderMorphList(item)
        ));
    }
    setArray(arr.current);
  }, [filtering]);


  /**
   * handleFilter
   * @description - This function is used to change the appearance of the filter buttons
   * @param event - The event of the button
   */
  const handleFilter = (event) => {
    if (
      !filteredButtons.includes(event.target.value)
    ) {
      setFilteredButtons([...filteredButtons, event.target.value]);
      event.target.className = "btn btn-active";
      document.getElementById("allButton").className = "btn btn-nonactive";
    }
    else if (event.target.className === "btn btn-active") {
      const index = filteredButtons.indexOf(event.target.value);
      event.target.className = "btn btn-nonactive";
      if (index >= 0) {
        const tempFilteredButtons = [...filteredButtons];
        tempFilteredButtons.splice(index, 1); // 2nd parameter means remove one item only
        setFilteredButtons(tempFilteredButtons);
      }
    }
  };

  /**
   * handleAllFilter
   * @description - This function is used to change the appearance of the reset/all button
   * @param event - The event of the button
   */
  const handleAllFilter = (event) => {
    setFilteredButtons([]);

    if ((event.target.classList = "all-btn btn-nonactive")) {
      event.target.className = "all-btn btn-active";

      let activeButtons = document.getElementsByClassName("btn btn-active");
      activeButtons = Array.from(activeButtons); // Convert HTMLCollection to array

      for (let i = 0; i < activeButtons.length; i++) {
        activeButtons[i].className = "btn btn-nonactive";
      }
    } else {
      event.target.className = "all-btn btn-nonactive";
    }
  };

  useEffect(() => {
    const filterButton = document.getElementById("filterButton");
    const filterButtonGrid = document.getElementById("filter-grid");

    if (isFilterOpen) {
      filterButton.className = "btn-filter btn-nonactive mb-4";
      filterButton.textContent = "Hide";
      filterButtonGrid.className = "grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4 text-md mb-[2em]";
    } else {
      filterButton.className = "btn-filter btn-nonactive mb-4";
      filterButton.textContent = "Filter";
      filterButtonGrid.className = "grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4 text-md mb-[2em] grid-hidden";
    }
  }, [isFilterOpen]);


  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | All</title>
        <meta
          name="description"
          content="All proven morphs for the western hognose snake, including Sunburst, Snow, Moonstone and more."
        />
      </MetaTags>
      <NavBar />
      <h1 className="lg:text-6xl text-5xl text-center mt-[0.3em]">
        All Morphs
      </h1>
      <div className="text-center mt-[2em] mx-[1.5em]">
        <p>
          Click the filter button to show the filters. By default the reset
          button is selected. You can deselect filters by clicking the filters
          again after selecting these or by clicking the reset button. The "i"
          button is for toggling the information of the morph.
        </p>
      </div>

      <div className="mx-[3em] lg:mt-[2em] mt-[2em] mb-[4em]">

        <button id="filterButton" className="py-5" onClick={
          () => { setFilterOpen(!isFilterOpen) }
        } > Filter </button>

        <div id="filter-grid"
          className={
            "grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4 text-md mb-[2em]"
          }
        >
          <button
            id="allButton"
            className="all-btn btn-active"
            value="All"
            onClick={(e) => handleAllFilter(e)}
          >
            Reset
          </button>
          <FilterButton value="Albino" name="Albino" onClick={handleFilter} />
          <FilterButton
            value="Anaconda"
            name="Anaconda"
            onClick={handleFilter}
          />
          <FilterButton
            value="Antarctic"
            name="Antarctic"
            onClick={handleFilter}
          />
          <FilterButton value="Arctic" name="Arctic" onClick={handleFilter} />
          <FilterButton
            value="Axanthic"
            name="Axanthic"
            onClick={handleFilter}
          />
          <FilterButton value="Caramel" name="Caramel" onClick={handleFilter} />
          <FilterButton
            value="Cinnamon"
            name="Cinnamon"
            onClick={handleFilter}
          />
          <FilterButton
            value="Colorblast"
            name="Colorblast"
            onClick={handleFilter}
          />
          <FilterButton value="Diablo" name="Diablo" onClick={handleFilter} />
          <FilterButton
            value="Hypo"
            name="Evan's Hypo"
            onClick={handleFilter}
          />
          <FilterButton value="Gaverth" name="Gaverth Green" onClick={handleFilter} />
          <FilterButton value="Granite" name="Granite" onClick={handleFilter} />
          <FilterButton value="GJ" name="Granite Jungle" onClick={handleFilter} />
          <FilterButton
            value="Lavender"
            name="Lavender"
            onClick={handleFilter}
          />
          <FilterButton
            value="Lemon"
            name="Lemon Ghost"
            onClick={handleFilter}
          />
          <FilterButton
            value="Leucistic"
            name="Leucistic"
            onClick={handleFilter}
          />
          <FilterButton value="Pastel" name="Pastel" onClick={handleFilter} />
          <FilterButton value="PPA" name="Pink Pastel" onClick={handleFilter} />
          <FilterButton
            value="Pistachio"
            name="Pistachio"
            onClick={handleFilter}
          />
          <FilterButton
            value="Purple"
            name="Purple Line"
            onClick={handleFilter}
          />
          <FilterButton value="Sable" name="Sable" onClick={handleFilter} />
          <FilterButton value="Savannah" name="Savannah" onClick={handleFilter} />
          <FilterButton value="Shadow" name="Shadow" onClick={handleFilter} />
          <FilterButton
            value="Skullface"
            name="Skullface"
            onClick={handleFilter}
          />
          <FilterButton
            value="Superarctic"
            name="Superarctic"
            onClick={handleFilter}
          />
          <FilterButton
            value="Superconda"
            name="Superconda"
            onClick={handleFilter}
          />
          <FilterButton
            value="Swiss"
            name="Swiss Chocolate"
            onClick={handleFilter}
          />
          <FilterButton
            value="Toffeebelly"
            name="Toffeebelly"
            onClick={handleFilter}
          />
          <FilterButton value="True" name="True Hypo" onClick={handleFilter} />
          <FilterButton value="Woma" name="Woma" onClick={handleFilter} />
        </div>
        {isMorphExisting ? (
          <div
            id="morph-card-grid"
            className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-10"
          >
            {array}
            <div
              className="loader"
              ref={(loader) => {
                setLoader(loader);
              }}
            />
          </div>

        ) : (<div className="lg:text-3xl text-xl text-center mt-[3em] mb-[5em]">
          <p>
            No such morphs found in the database. These are either not yet bred
            or still need to be added.
          </p>
        </div>)
        }
        <ScrollButton />
      </div>
    </>
  );
}

export default AllMorphsPage;
