import { lazy, Suspense } from "react";

function MorphList(props) {
  const morphs = props.items.filterMorphs || "";
  const isSimple = props.items.isSimple || false;
  const HeavyLoading = lazy(() => import("../Common/MorphCard"));

  return (
    // <MorphCard
    //   image={props.items.image}
    //   title={props.items.title}
    //   description={props.items.description}
    //   link={props.items.link}
    //   credits={props.items.credits}
    //   isFacebook={props.items.isFacebook}
    //   isWebsite={props.items.isWebsite}
    //   morphs={morphs}
    //   isSimple={isSimple}
    // />
    <Suspense fallback={<div> </div>}>
      <HeavyLoading
        image={props.items.image}
        title={props.items.title}
        description={props.items.description}
        link={props.items.link}
        credits={props.items.credits}
        isFacebook={props.items.isFacebook}
        isWebsite={props.items.isWebsite}
        morphs={morphs}
        isSimple={isSimple}
      />
    </Suspense>
  );
}

export default MorphList;
