import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "../styles/App.css";

import HomePage from "./Pages/HomePage";
import MorphsPage from "./Pages/MorphsPage";
import CalculatorPage from "./Pages/CalculatorPage";
import GeneticsPage from "./Pages/ContactPage";
import NotFoundPage from "./Pages/NotFoundPage";
import RecessiveMorphsPage from "./Pages/morphPages/RecessiveMorphsPage";
import IncompleteMorphsPage from "./Pages/morphPages/IncompleteMorphsPage";
import DominantMorphsPage from "./Pages/morphPages/DominantMorphsPage";
import PolygenicMorphsPage from "./Pages/morphPages/PolygenicMorphsPage";
import OtherMorphsPage from "./Pages/morphPages/OtherMorphsPage";
import AllMorphsPage from "./Pages/morphPages/AllMorphsPage";

function App() {

  return (
    
    <Router>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        <Route path="/morphs" exact element={<MorphsPage />} />
        <Route path="/morphs/recessive" exact element={<RecessiveMorphsPage/>} />
        <Route path="/morphs/incomplete-dominant" exact element={<IncompleteMorphsPage/>} />
        <Route path="/morphs/dominant" exact element={<DominantMorphsPage/>} />
        <Route path="/morphs/all" exact element={<AllMorphsPage/>} />
        <Route path="/morphs/polygenic" exact element={<PolygenicMorphsPage/>} />
        <Route path="/morphs/other" exact element={<OtherMorphsPage/>} />
        <Route path="/calculator" exact element={<CalculatorPage />} />
        <Route path="/contact" exact element={<GeneticsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
