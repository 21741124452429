import React from "react";
import { MetaTags } from "react-meta-tags";
import FacebookIcon from "../../media/svg/FacebookIcon";
import "../../styles/ContactPage.css";
import NavBar from "../Common/NavBar";

function ContactPage() {
  const insta = require("../../media/images/insta.webp");
  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Contact</title>
        <meta
          name="description"
          content="How and when to contact the Hognose Morphs site owner."
        />
      </MetaTags>
      <NavBar />
      <div>
        <h1 className="lg:text-6xl text-5xl text-center mt-[0.3em]">
          Contact
        </h1>

        <div className="lg:mt-[1.5em] grid lg:grid-cols-2 md:grid-cols-1 gap-[5em] mb-[2em] ">
          <div className="textbox mt-[1em] text-lg lg:col-span-2 md:col-span-2">
            <p>
              All photos on Hognose Morphs are used for nonprofit educational
              purposes. Every photo is credited to the original owner. All
              owners pages or websites are linked, except for personal pages. If
              you have better photos, descriptions, new morphs, want your
              personal page linked or want your own pictures removed, please
              send a message. It is highly appreciated if descriptions of the
              morphs are added when photos are being sent. Questions or
              comments? Please contact one of the Hognose Morphs social media
              pages.
            </p>
          </div>

          <div className="facebook-contact-box ">
            <div className="mt-[0.1em]">
              <FacebookIcon w="11.5em" h="11.5em" />
            </div>

            <p className="face-text ">Contact via Facebook page.</p>
            <button
              className="facebook-button"
              onClick={() => {
                window.open(
                  "https://www.facebook.com/hognose.morphs.mutations"
                );
              }}
            >
              Contact
            </button>
          </div>

          <div className="instagram-contact-box">
            <img
              src={insta}
              alt="instagram_logo"
              className="w-[10em] h-[10em] mx-auto "
            />

            <p className="insta-text">Contact via Instagram page.</p>

            <button
              className="instagram-button"
              onClick={() => {
                window.open("https://www.instagram.com/hognosemorphs/");
              }}
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactPage;
