import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";

import NavBar from "../../Common/NavBar";

import "../../../styles/MorphsPages.css";
import axios from "axios";
import MorphList from "../../Common/MorphList";
import ScrollButton from "../../Common/ScrollButton";

function OtherMorphsPage() {
  const [cardInfo, setCardInfo] = useState([]);

  //axios request to get else data from database and set cardInfo to the data
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/morphs/type/else`)
      .then((res) => {
        setCardInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderList = () => {
    let array = cardInfo.slice(0, cardInfo.length).map((item) => {
      return (
        <div className="card" key={item.title}>
          <MorphList items={item} />
        </div>
      );
    });
    return array;
  };

  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Other</title>
        <meta
          name="description"
          content="All the other unproven morphs for the western hognose snake, including Normal and Diablo."
        />
      </MetaTags>
      <NavBar />
      <h1 className="lg:text-6xl text-5xl text-center mt-[0.3em]">Other</h1>
      <div className="text-center mt-[2em] mx-[2em]">
        <p>
          Other morphs consists of the normal morph and unproven morphs. For
          these unproven morphs it is still somewhat unknown how they are
          reproducible.
        </p>
      </div>
      <div className="mx-[3em] lg:mt-[3em] mt-[2em] mb-[4em]">
        <div
          id="morph-card-grid"
          className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-10"
        >
          {renderList()}
        </div>
        <ScrollButton />
      </div>
    </>
  );
}

export default OtherMorphsPage;
