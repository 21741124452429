import React from "react";
import { MetaTags } from "react-meta-tags";
import "../../styles/ComingSoonPage.css";
import NavBar from "../Common/NavBar";

function NotFoundPage() {
  const logo = require("../../media/images/logo.webp");
  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Not Found</title>
      </MetaTags>
      <NavBar />
      <div className="center-page lg:mt-[2em] mt-[4em]">
        <img
          className="lg:h-[25em] lg:w-[25em] horizontal-center md:h-[10em] md:w-[10em] h-[15em] w-[15em]"
          src={logo}
          alt="logo"
        ></img>
        <h1 className="header-title lg:text-8xl lg:mt-[0.5em] text-center text-4xl mt-[2em]">
          404
        </h1>
        <h2 className="header-title lg:text-4xl mt-[1em] text-center text-xl">
          PAGE NOT FOUND
        </h2>
      </div>
    </>
  );
}

export default NotFoundPage;
