import React from "react";
import { MetaTags } from "react-meta-tags";

import NavBar from "../Common/NavBar";
import MorphsPageBody from "./MorphsPageBody";

import "../../styles/MorphsPage.css";

function MorphsPage() {
  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Morphs</title>
        <meta
          name="description"
          content="All sorts of hognose morphs. Selecting can be done from recessive, dominant, incomplete dominant, polygenic, combinations and other morphs."
        />
      </MetaTags>
      <NavBar />
      <MorphsPageBody />
    </>
  );
}

export default MorphsPage;
