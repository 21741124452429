import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Collapse } from "react-collapse";

import NavBar from "../Common/NavBar";

import "../../styles/CalculatorPage.css";

const CalculatorPage = () => {
  const [isOpened, setIsOpened] = useState(true);

  const toggleCollapse = (e) => {
    e.target.classList.toggle("down");
    setIsOpened(!isOpened);
  };

  return (
    <>
      <MetaTags>
        <title>Hognose Morphs | Calculator</title>
        <meta
          name="description"
          content="Calculator that can be used for calculating the clutch outcomes for western hognose snakes."
        />
      </MetaTags>
      <NavBar />
      <h1 className="lg:text-6xl text-5xl text-center mt-[0.3em]">
        Calculator
      </h1>
      <div className="text-center mt-[2em] mx-[2em] lg:mb-[1em] md:mb-[0em]">
        <Collapse isOpened={isOpened}>
          <p>
            The hognose morph calculator is used to calculate to offspring of
            certain pairings. Select the known genetics of each parent snake to
            see potential offspring colors. <br />
            Het. (heterozygous) is used to describe a snake that carries a
            specific gene but does not express it. However, with dominant traits
            the het. form expresses a different phenotype than a wild type.
            Homozygous is used to describe a snake that carries two copies of a
            specific gene. For dominant traits the homozygous form looks the
            same as the het. form. <br />
            Note that the calculator does not give outcomes with 50% het. (from
            het. x normal) or 66% het. (from het. x het.) pairings. It only
            gives all the possible outcomes on a genetic level, so heterozygous
            and homozygous.
          </p>
        </Collapse>
        <button className="arrow" onClick={(e) => toggleCollapse(e)}></button>
      </div>
      <iframe
        className="iframe"
        height="70%"
        width="100%"
        title="Hognose Morph Calculator"
        src={"calc/index.html"}
      />
    </>
  );
};

export default CalculatorPage;
