function BrokenSVG(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.000000 32.000000"
      preserveAspectRatio="xMidYMid meet"
      fill="#fff"
      className={props.className}
    >
      <g
        transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M235 250 c-10 -11 -15 -23 -12 -27 8 -7 47 25 47 39 0 14 -16 9 -35
-12z"
        />
        <path
          d="M155 170 c-38 -40 -23 -52 16 -13 17 17 27 33 22 36 -6 4 -23 -7 -38
-23z"
        />
        <path
          d="M70 85 c-13 -14 -20 -28 -17 -32 8 -7 57 35 57 49 0 15 -16 8 -40
-17z"
        />
      </g>
    </svg>
  );
}

export default BrokenSVG;
