function BackArrowSVG(props) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 626.000000 626.000000"
      className={props.className}
      fill="#fff"

    >
      <g
        transform="translate(0.000000,626.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1254 5611 c-28 -9 -73 -34 -100 -54 -27 -20 -298 -285 -602 -589
l-552 -554 608 -608 c351 -351 625 -617 646 -628 158 -81 317 -57 440 67 73
72 100 141 100 255 0 135 -21 170 -215 365 -87 89 -159 166 -159 173 0 16
2707 17 2873 2 403 -38 741 -228 973 -546 174 -238 256 -490 257 -784 1 -389
-152 -739 -439 -1001 -199 -182 -478 -310 -736 -338 -65 -7 -713 -11 -1966
-11 -1214 0 -1888 -4 -1922 -10 -79 -15 -149 -51 -201 -104 -57 -59 -85 -113
-97 -191 -29 -184 70 -351 242 -408 45 -16 210 -17 1986 -17 1881 0 1941 1
2081 20 361 50 696 191 985 415 179 138 381 365 498 557 152 251 234 484 287
813 22 133 16 446 -10 595 -86 489 -297 878 -653 1206 -289 267 -638 436
-1063 516 l-120 22 -1494 3 -1493 4 134 137 c143 146 171 190 190 290 25 136
-44 293 -162 367 -89 55 -217 70 -316 36z"
        />
      </g>
    </svg>
  );
}

export default BackArrowSVG;
